import React from "react"

import ColoredBlock from "./ColoredBlock"
import { COLORS } from "../styles/constants"
import styles from "./contact.module.css"

const Contact = () => (
  <ColoredBlock
    backgroundColor={"#fff"}
    color={COLORS.primaryBlack}
    style={{
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
    }}
  >
    <h2 style={{ color: COLORS.accent }}>Stay in Touch</h2>

    <p>
      Email us at:{` `}
      <a
        style={{ textDecoration: `none`, color: COLORS.accent }}
        href="mailto:contact@thedreamforge.com"
      >
        contact@thedreamforge.com
      </a>
    </p>
  </ColoredBlock>
)

export default Contact
