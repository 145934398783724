import React from "react"
import PropTypes from "prop-types"

import ColoredBlock from "./ColoredBlock"
import { COLORS } from "../styles/constants"

import SectionHeader from "./section-header"

const About = ({ siteTitle }) => (
  <ColoredBlock
    backgroundColor={COLORS.accent}
    color={COLORS.primaryBlack}
    style={{
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
    }}
  >
    <SectionHeader title="About The Dream Forge" />
    <p style={{ textAlign: "center", maxWidth: 440 }}>
      The Dream Forge turns dreams and ideas into code. We specialize in React
      Native solutions and games leveraging web technologies.
    </p>
  </ColoredBlock>
)

About.propTypes = {
  siteTitle: PropTypes.string,
}

About.defaultProps = {
  siteTitle: ``,
}

export default About
