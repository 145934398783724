import React from "react"

import { COLORS } from "../styles/constants"

const ColoredBlock = props => (
  <div
    style={{
      ...props.style,
      padding: "4rem 1rem",
      backgroundColor: props.backgroundColor,
      color: props.color,
    }}
  >
    {props.children}
  </div>
)

export default ColoredBlock
